module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Marys Deweloper","short_name":"Marys","start_url":"/","background_color":"#fff","theme_color":"#EEC83B","display":"minimal-ui","icon":"src/assets/favicon/favicon-32x32.png","icons":[{"src":"src/assets/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aeff15a49732c2c8bd594b333d50d09d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-53PS2XZ"]},
    }]
